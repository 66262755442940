@tailwind base;
@tailwind components;
@tailwind utilities;

.loading {
  @apply absolute top-0 bottom-0 right-0 left-0 bg-white z-20 opacity-80 flex w-full h-full justify-center items-center;
}

.loader {
  @apply animate-spin h-6 w-6 mr-2 border-2 border-gray-600 border-t-transparent rounded-full;
}

.loader-message {
  @apply mt-2 text-gray-600 text-sm;
}

.sharelo-narration-rounded > video {
  @apply w-24 h-24 sm:w-36 sm:h-36 object-cover scale-x-100 rounded-full;
}

.custom-scrollable::-webkit-scrollbar {
  width: 0.3rem;
  background-color: #111827;
  border-radius: 8px;
}

.custom-scrollable::-webkit-scrollbar-track {
  background: #111827;
  border-radius: 8px;
}

.custom-scrollable::-webkit-scrollbar-thumb {
  background: #e5e7eb;
  border-radius: 8px;
}

.text-color-picker-warp .react-colorful,
.bg-color-picker-warp .react-colorful {
  width: 100%;
  padding: 0.25rem;
  background: #fff;
}

.text-color-picker-warp .react-colorful__saturation,
.bg-color-picker-warp .react-colorful__saturation {
  margin: 0 0 1rem;
  border-radius: 0.5rem;
}

.text-color-picker-warp .react-colorful__last-control,
.bg-color-picker-warp .react-colorful__last-control {
  border-radius: 0.5rem;
}

.text-color-picker-warp .react-colorful__hue,
.text-color-picker-warp .react-colorful__alpha,
.bg-color-picker-warp .react-colorful__hue,
.bg-color-picker-warp .react-colorful__alpha {
  height: 0.75rem;
  border-radius: 0.5rem;
}

.text-color-picker-warp .react-colorful__pointer {
  width: 0.75rem;
  height: 0.75rem;
}

.swiper-button-prev:after {
  font-size: 1rem !important;
  color: #fff;
}

.swiper-button-next:after {
  font-size: 1rem !important;
  color: #fff;
}
